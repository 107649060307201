<template>
  <section
    v-if="mostSales.length"
    id="MostsalesProductSlider"
    class="recommended-products container pt-4  mb-sm-1 mb-4 position-relative"
  >
    <div class="d-flex flex-wrap justify-content-center align-items-baseline">

      <BigTitle class="mb-2 mb-sm-4" title="پرفروش ترین کتونی ها" />

      <!--      <div class="line w-50"></div>-->
    </div>

    <div class="recommended-content mt-2 mt-sm-4">
      <div class="recommended-content-item position-relative" id="home">
        <div :style="`min-height: ${minHeight}px`">
          <div
            class="swiper-container"
            v-if="!disabledSliders.includes('discountededProductSlider')"
          >
            <div class="swiper-wrapper">
              <Product
                class="swiper-slide"
                :style="'max-width:' + maxWidth + 'px'"
                v-for="(product, index) in mostSales"
                :key="index"
                :product="product"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-next">
          <ZArrowBold style="transform: rotate(180deg)" />
        </div>
        <div class="swiper-button-prev">
          <ZArrowBold />
        </div>
      </div>
    </div>
        <div class="  d-flex justify-content-center ">
      <router-link to="/products?sort=top_sales" class="px-3 py-1 btnMorebtn">
        مشاهده همه 
      </router-link>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";
import BigTitle from "../nicecomponents/shared/BigTitle.vue";

let breakpoints = {
  300: {
    slidesPerView: 2,
    spaceBetween: 4,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 1,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 1,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};

export default {
  name: "discountededProductSlider",
  components: { ZArrowBold, Product, BigTitle },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",

      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    products() {
      this.initSlider(true);
    },
  },
  mounted() {
    this.initSlider(false);
    this.setMaxWidth();
    window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    products() {
      // return Array(6).fill(null) new_products
      let homeData = this.$store.getters["front/getHomeData"];
      if (homeData) {
        return homeData.new_products;
      } else {
        return [];
      }
    },
    mostSales() {
      // return Array(6).fill(null) new_products
      let homeData = this.$store.getters["front/getHomeData"];
      if (homeData) {
        return homeData.mostSales;
      } else {
        return [];
      }
    },
    // mostDiscount() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return Array(this.showCount).fill(null);
    //   } else {
    //     return this.getEnough(this.$store.getters["front/getHomeData"].mostDiscount);
    //   }
    // },
  },
  methods: {
    onResize() {
      this.setMaxWidth();
    },

    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      if (document.querySelector("#MostsalesProductSlider"))
        this.maxWidth =
          (document
            .querySelector("#MostsalesProductSlider")
            .getBoundingClientRect().width -
            spaceBetween * slidesPerView) /
            slidesPerView +
          (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("discountededProductSlider", kill, () => {
      
        this.slider = new Swiper("#MostsalesProductSlider .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
          navigation: {
            nextEl: "#MostsalesProductSlider .swiper-button-next",
            prevEl: "#MostsalesProductSlider .swiper-button-prev",
          },
          breakpoints,
        });
        setTimeout(() => {
          if (
            document.querySelector("#MostsalesProductSlider .swiper-container")
          )
            this.minHeight = document
              .querySelector("#MostsalesProductSlider .swiper-container")
              .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
  },
};
</script>

<style scoped>
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swiper-container {
  animation: fade 450ms;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  background: var(--color-theme);
  height: 38px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev {
  right: -10px;
  transform: translateY(-40px);
}
.swiper-button-next {
  left: -10px;
  right: unset;
  transform: translateY(-40px);
}

.nav-link {
  color: #696969 !important;
}
.nav-link:hover {
  color: white !important;
}
</style>
<style>
.nav-link.active {
  color: black !important;
  font-weight: bold;
  font-size: 1.1rem !important;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  width: 50%;
  height: 2px;
  background: var(--color-theme);
  opacity: 0.7;
}

.recommended-products-more {
  background: var(--color-theme);
  color: white;
}

.recommended-products-more:hover {
  background: #000;
  color: white;
}









.btnMorebtn{
  padding: 7px 55px !important;
  color: white;
  font-size: 18px;
  border-radius: 20px;
  background-color: purple;
  margin: 35px 0 60px 0px;
}

.btnMorebtn:hover{
  color: white;
  background-color: var(--color-theme);
  transition: 0.25s;

}
@media screen and (max-width : 1216px) {

  .btnMorebtn{
  margin: 25px 0 60px 0px;
}
} 
</style>
