<template>
  <svg @click="e => $emit('click', e)" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <rect fill="currentColor" width="24" height="24" transform="rotate(180 12 12)"
          opacity="0"></rect>
    <circle fill="currentColor" cx="4" cy="12" r="1"></circle>
    <rect fill="#000" x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
    <rect fill="currentColor" x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect>
    <rect fill="currentColor" x="3" y="6" width="18" height="2" rx=".94" ry=".94"></rect>
  </svg>
</template>

<script>
export default {
  name: "NavSvg"
}
</script>

<style scoped>

</style>
