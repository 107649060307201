<template>
  <section id="brands"  class="position-relative w-100">

      <div id="title">
      <img src="@/assets/images/brands.png" alt="brands" />
      <h2>برندها</h2>
    </div>



    <div id="content">
      <router-link
        v-for="(item, index) in $store.getters['front/getHomeData'].brands"
        :to="`/products/brand/${item.id}/${getSlug(item)}`"
        :key="index"
        class="brandItem"
      >
        <img :src="item.image.url" :alt="item.name" />
      </router-link>



    </div>


   
  </section>

</template>
<script>
export default {
  methods: {
    getSlug(category) {
      let slug = category.name;
      if (slug == "" || slug == null) {
        slug = "brand";
      } else {
        slug = category.name.replace(/ /g, "-");
      }
      return slug;
    },
  },
};
</script>
<style scoped>
section#brands #title h2 {
  position: absolute;
  font-size: 36px;
  color: #0b0b61;
  top: 50%;
  font-family: "yekan-bakh";
  transform: translateY(-50%);
}
section#brands #title img {
  width: 25%;
}
section#brands #title {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
section#brands {
  width: 100%;
  padding-top: 30px;
}
section#brands #content .brandItem strong {
  font-size: 20px;
  font-family: "Font Awesome 5 Free";
  color: black;
}

section#brands #content .brandItem {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  /* transition: all 1s; */
}
section#brands #content {
  width: 100%;
  margin: 15px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
/* .brandItem:hover{
  transform: scale(1.33);
} */
@media (max-width: 768px) {
  section#brands #content {
    display: flex;
    overflow-x: scroll;
  }
  section#brands #title h2 {
    display: none;
  }
}
section#brands #content::-webkit-scrollbar {
  /* width: 2px;
  height: 5px; */
  display: none;
}

/* Track */
/* section#brands #content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
/* section#brands #content::-webkit-scrollbar-thumb {
  background: #3c1a5b;
  border-radius: 10px;
} */
@media (max-width: 480px) {
  section#brands #content {
    grid-template-columns: repeat(2, 1fr);
  }
  section#brands #content .brandItem {
    padding: 5px;
  }
}


</style>
