<template>
  <section
    id="DiscountedProductSlider"
    v-if="mostDiscount.length"
    class="recommended-products Discounted_roduct-slider   pt-4 mt-2 mt-sm-4 mb-sm-5 mb-4 d-flex justify-content-between align-items-center gap20"
  >
    <BigTitle class="mb-2 mb-sm-4" title="پیشنهاد شگفت انگیز" />

    <div id="titleAndLink">
      <h2>
        <p>
          <q>
            پیشنهاد
            <br />
            شگفت انگیز
          </q>
        </p>
      </h2>
      <img src="@/assets/images/amazingBox.png" alt="amazing" />
      <router-link to="/products/?sort=most_discount">مشاهده همه</router-link>
    </div>
    <div
      class="recommended-content-container recommended-content-item position-relative amazingProducts"
      id="home"
    >
      <div :style="`min-height: ${minHeight}px`">
        <div
          class="swiper-container homeSliderC"
          v-if="!disabledSliders.includes('discountededProductSlider')"
        >
          <div class="swiper-wrapper">
            <Product
              class="swiper-slide"
              :style="'max-width:' + maxWidth + 'px'"
              v-for="(product, index) in mostDiscount"
              :key="index"
              :product="product"
              :priceColorFlag="true"
            />
          </div>
        </div>
      </div>
      <div class="swiper-button-next">
        <ZArrowBold style="transform: rotate(180deg)" />
      </div>
      <div class="swiper-button-prev">
        <ZArrowBold />
      </div>
    </div>
  </section>
</template>

<script>
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import debounce from "lodash/debounce";
import { getImage } from "@/libraries/functions";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";
import BigTitle from "../nicecomponents/shared/BigTitle.vue";

let breakpoints = {
  300: {
    slidesPerView: 2,
    spaceBetween: 4,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 1,
    centerMode: false,
  },
  768: {
    slidesPerView: 3,
    spaceBetween: 1,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};

export default {
  name: "discountededProductSlider",
  components: { ZArrowBold, Product, BigTitle },
  data() {
    return {
      minHeight: "unset",
      maxWidth: "unset",

      sliderIsLoading: true,
      onResizeFunction: debounce(this.onResize, 150),
    };
  },
  watch: {
    products() {
      this.initSlider(true);
    },
    mostDiscount() {
      this.onResizeFunction();
    },
  },
  mounted() {
    this.initSlider(false);
    this.setMaxWidth();
    // window.addEventListener("resize", this.onResizeFunction);
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.onResizeFunction);
  },
  computed: {
    products() {
      // return Array(6).fill(null) new_products
      let homeData = this.$store.getters["front/getHomeData"];
      if (homeData) {
        return homeData.new_products;
      } else {
        return [];
      }
    },
    mostDiscount() {
      // return Array(6).fill(null) new_products
      let homeData = this.$store.getters["front/getHomeData"];
      if (homeData) {
        return homeData.mostDiscount;
      } else {
        return [];
      }
    },
    // mostDiscount() {
    //   if (this.$store.getters["front/getHomeData"] == null) {
    //     return Array(this.showCount).fill(null);
    //   } else {
    //     return this.getEnough(this.$store.getters["front/getHomeData"].mostDiscount);
    //   }
    // },
  },
  methods: {
    onResize() {
      this.setMaxWidth();
      this.initSlider(true);
    },

    setMaxWidth() {
      let slidesPerView = this.getSlidesPerView();
      let spaceBetween = slidesPerView.spaceBetween;
      slidesPerView = slidesPerView.slidesPerView;
      if (document.querySelector("#DiscountedProductSlider"))
        this.maxWidth =
          (document
            .querySelector("#DiscountedProductSlider")
            .getBoundingClientRect().width -
            spaceBetween * slidesPerView) /
            slidesPerView +
          (window.innerWidth > 768 ? 2 : 0);
    },
    getSlidesPerView() {
      let c = Number.parseInt(window.innerWidth);
      let ok = 1;
      let max = -1;
      Object.keys(breakpoints).forEach((b) => {
        b = Number.parseInt(b);
        if (c > b && b > max) {
          ok = breakpoints[b];
          max = b;
        }
      });
      return ok;
    },
    initSlider(kill = false) {
      this.initSwiper("discountededProductSlider", kill, () => {
        this.slider = new Swiper("#DiscountedProductSlider .swiper-container", {
          spaceBetween: 1,
          //   centeredSlides: true,
          loop: false,
          speed: 500,
          autoplay: false,
          navigation: {
            nextEl: "#DiscountedProductSlider .swiper-button-next",
            prevEl: "#DiscountedProductSlider .swiper-button-prev",
          },
          breakpoints,
        });
        setTimeout(() => {
          if (
            document.querySelector("#DiscountedProductSlider .swiper-container")
          )
            this.minHeight = document
              .querySelector("#DiscountedProductSlider .swiper-container")
              .getBoundingClientRect().height;
        }, 1700);
      });
    },
    // گرفتن مقدار لازم براساس تعدادی که باید نمایش بدیم بر اساس سایز صفحه
  },
};
</script>

<style scoped>
#DiscountedProductSlider {
  background-color: #8a149c;
  padding: 16px;
  width: 90%;
  margin: auto;
  border-radius: 40px;
  overflow: hidden;
}
@media (max-width: 768px) {
  #DiscountedProductSlider {
    width: 100%;
    border-radius: 0;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.swiper-container {
  animation: fade 450ms;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #000000;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  background: var(--color-theme);
  height: 38px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-button-prev {
  right: -10px;
  transform: translateY(-40px);
}
.swiper-button-next {
  left: -10px;
  right: unset;
  transform: translateY(-40px);
}

.nav-link {
  color: #696969 !important;
}
.nav-link:hover {
  color: white !important;
}
</style>
<style>
.nav-link.active {
  color: black !important;
  font-weight: bold;
  font-size: 1.1rem !important;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  width: 50%;
  height: 2px;
  background: var(--color-theme);
  opacity: 0.7;
}

.recommended-products-more {
  background: var(--color-theme);
  color: white;
}

.recommended-products-more:hover {
  background: #000;
  color: white;
}
.recommended-content-container .recommended-content-item article:hover {
  transform: unset;
}
@media (max-width: 500px) {
}
#titleAndLink * {
  margin: 0;
}
#titleAndLink img {
  width: 200px;
}
#titleAndLink {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#titleAndLink q {
  color: white;
}
#titleAndLink a {
  color: white;
  border-radius: 5px;
  border: 1px solid white;
  padding: 8px 16px;
  transition: all 1.2s;
}
#titleAndLink a:hover {
  background-color: white;
  color: #8a149c;
}
#DiscountedProductSlider #home {
  flex-grow: 1;
}
.gap20 {
  gap: 20px;
}
</style>
