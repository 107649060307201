<template>
  <div>
    <div class="category">
      <CategoryCard v-for="cat in categories" :item="cat" />
    </div>
  </div>
</template>
<script>
import CategoryCard from "@/parts/Front/nicecomponents/subcomponents/CategoryCard";

export default {
  name: 'HomeCategories',
  components: {CategoryCard},
  computed: {
    categories() {
      return [
        {
          title: 'بچگانه',
          image: {
            url: require('@/assets/images/2.png')
          },
          to: '/products/category/69/اکسسوری'
        },
        {
          title: 'زنانه',
          image: {
            url: require('@/assets/images/3.png')
          },
          to: '/products/category/15/زنانه'
        },
        {
          title: 'مردانه',
          image: {
            url: require('@/assets/images/4.png')
          },
          to: '/products/category/68/مردانه'
        },

      ]
    }
  }
}
</script>
<style scoped>
.category {
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

</style>
