<template>
  <div>
    <MainSearch />
    <!-- resp login Modal -->
    <RespLoginModal />
    <CartLists />
    <!-- <WhatsappContact /> -->
    <div >
        <FirstHeader v-if="$route.path != '/'" />
        <Header v-if="$route.path != '/'" />
        
        <router-view :key="$route.path"></router-view>
        <Footer />
        <FooterButtons></FooterButtons>
    </div>
    <div id="mainLoader" v-show="status">
      <template v-if="status">
        <div class="main-loader-image">
          <img
            width="150"
            class="loader-logo"
            src="@/assets/images/logo.png"
            alt="logo"
          />
        </div>
        <div class="main-loader-alphabets">
          <span>r</span>
          <span>a</span>
          <span>k</span>
          <span>a</span>
          <span>a</span>
          <span>a</span>
          <span>P</span>
        </div>
      </template>
    </div>
    <Compare @entered="showCompareContent=true"/>
    <transition name="fade">
      <CompareContent @left="showCompareContent=false" v-if="!mediaQueries.mobileSize && Boolean(compares.length) && showCompareContent"/>
    </transition>
  </div>
</template>
<script>
import FirstHeader from "@/parts/Front/nicecomponents/subcomponents/FirstHeader";

import "bootstrap";
import Header from "@/parts/Front/components/shared/HeaderSecond";
import Footer from "@/parts/Front/components/shared/Footer";
import FooterButtons from "@/parts/Front/components/FooterButtons";
import Compare from "@/parts/Front/components/shared/Compare";
import CompareContent from "@/parts/Front/components/shared/CompareContent";
// import WhatsappContact from "../components/WhatsappContact";
import MainSearch from "@/parts/Front/components/MainSearch";
import RespLoginModal from "@/parts/Front/components/RespLoginModal";
import CartLists from "@/parts/Front/components/CartLists";
import AtlasLogo from '@/parts/Front/components/AtlasLogo.vue';

//   در هر کامپوننت میتوانیم از این متغییر استفاده کنیم نحوه استفاده در کامپوننت زیر
// RespLoginModal
export default {
  name: "Home",
  components: {
    // WhatsappContact,
    FirstHeader,
    Header,
    Footer,
    FooterButtons,
    Compare,
    CompareContent,
    MainSearch,
    RespLoginModal,
    CartLists,
    AtlasLogo,
  },
  inject: ['mediaQueries'],
  data() {
    return {
      showCompareContent: false
    }
  },
  computed: {
    status() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    compares() {
      console.log(this.$store.getters['front/getCompares'])
      return this.$store.getters['front/getCompares'];
    }
  },
  created() {
    this.$store.dispatch("front/getHomeDataFromServer", {
      $root: this.$root
    });
  },
};
</script>
<style scoped>


#mainLoader {
  position: fixed;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  background: rgba(138, 114, 114, 0.9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#mainLoader .main-loader-alphabets {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
#mainLoader .main-loader-alphabets span {
  height: 25px;
  /* width: 25px; */
  margin-right: 10px;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  animation: loading 1.5s linear infinite;
}
/* Creating the loading animation*/
@keyframes loading {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

#mainLoader .main-loader-alphabets span:nth-child(1) {
  animation-delay: 0.1s;
}
#mainLoader .main-loader-alphabets span:nth-child(2) {
  animation-delay: 0.2s;
}
#mainLoader .main-loader-alphabets span:nth-child(3) {
  animation-delay: 0.3s;
}
#mainLoader .main-loader-alphabets span:nth-child(4) {
  animation-delay: 0.4s;
}
#mainLoader .main-loader-alphabets span:nth-child(5) {
  animation-delay: 0.5s;
}
#mainLoader .main-loader-alphabets span:nth-child(6) {
  animation-delay: 0.6s;
}
</style>
