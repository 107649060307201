<template>
  <Dropdown @hide="readNotifications" id="header-notification"
      dropdown-styles="transform: translateX(-50%)"
      button-container-styles="display:inline"
      class="inline"
  >
    <template v-slot:button>
      <div class="d-inline-block position-relative">
        <button class="bell-btn">
          <notification-bell
              :icon="require('@/assets/images/bell.svg')"
              :size="25"
              :count="totalUnread"
              :upperLimit="999"
              counterLocation="upperLeft"
              counterStyle="roundRectangle"
              counterBackgroundColor="#9d3f3f"
              counterTextColor="#FFFFFF"
              iconColor="#000000"
          />
        </button>
      </div>
    </template>
    <template v-slot:dropdown>
      <div class="notification-dropdown-container" @scroll="onScroll">
        <p class="pr-4 mb-1">اعلانات</p>
        <ul v-if="notifications != ''">
          <router-link
              class="notification-item"
              v-for="notification in notifications"
              tag="li"
              :to="notification.to"
              style="border-radius: 10px 10px 0 0"
          >
            <div class="notification-title">
              <span class="notfiTitle">{{ notification.title }} <span class="badge badge-danger mr-1" style="transform: translateY(-1px)" v-if="!notification.read">جدید</span></span>
              <span class="created-at">{{ moment(notification.created_at).format('HH:mm YYYY/MM/DD') }}</span>
            </div>
            <div style="opacity: 0;">
              <span>{{ notification.title }}</span>
              <span>تاریخ: {{ moment(notification.created_at).format('HH:mm YYYY/MM/DD ') }}</span>
            </div>
            <div class="notification-description">{{ notification.description }}</div>
          </router-link>
        </ul>
        <p class="text-center" v-else>هنوز هیچ اعلانی ثبت نشده</p>
        <Loading v-if="loading" />
      </div>
    </template>
  </Dropdown>
</template>

<script>
import NotificationBell from 'vue-notification-bell'
import Dropdown from "@/components/Dropdown";
import Loading from '@/parts/Front/components/shared/Loading'


export default {
  name: "HeaderNotification",
  components: {
    Loading,
    NotificationBell,
    Dropdown
  },
  props: {
    value: Object
  },
  data() {
    return {
      items: this.value.items,
      totalUnread: this.value.totalUnread,
      reachedEnd: false,
      loading: false
    }
  },
  computed: {
      notifications() {
        return this.items.map(item => {
          return item.getNotification()
        })
      }
  },
  watch: {
    value: {
      handler: function (newVal) {
        this.items = newVal.items;
        this.totalUnread = newVal.totalUnread;
      }
    },
    items() {
      this.emit(this.items, this.totalUnread)
    },
    totalUnread() {
      this.emit(this.items, this.totalUnread)
    }
  },
  methods: {
    readNotifications() {
      if (this.totalUnread > 0) {
        this.totalUnread = 0;
        this.$store.getters['front/getRealNotifications'].items.forEach(notification => {
          notification.read_at = new Date();
        });
        // جوابش مهم نیست
        this.$axios.post('customer/notifications/read');
      }

    },
    emit(items, totalUnread) {
      this.$emit('input', {
        items,
        totalUnread
      })
    },
    onScroll(e) {
      if (((e.target.scrollHeight - e.target.scrollTop) < 500) && !this.reachedEnd && !this.loading) {
        this.loadItems()
      }
    },
    async loadItems() {
      let lastCreatedAt = !this.items.length ? null : this.items[this.items.length - 1].created_at;
      let month = Number.parseInt(this.moment(lastCreatedAt).format('M'));
      if (month < 10) {
        month = '0' + month
      }
      lastCreatedAt = this.moment(lastCreatedAt).format(`YYYY-${month}-D HH:mm:ss`)
      this.loading = true;
      try {
        const response = await this.$axios.get('customer/notifications', {
          params: {
            last_created_at: lastCreatedAt
          }
        })
        let notifications = response.data.data.notifications;
        if (notifications.length === 0) {
          this.reachedEnd = true
        } else {
          this.$store.commit('front/loadMoreNotifications', notifications)
        }
      } catch (error) {
        this.reachedEnd = true
        this.$root.warningNotify('مشکلی در دریافت اعلانات بوجود آمد')
      }
      this.loading = false;

    }
  }
}
</script>

<style scoped>
  .bell-btn {
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-58%);
  }

  .notification-dropdown-container {
    background: white;
    padding: 20px 0;
    position: relative;
    max-width: 600px;
    min-width: 500px;
    box-shadow: 0 0 10px -5px #6f6f6f;
    max-height: 350px;
    overflow: auto;
  }

  .notification-dropdown-container::-webkit-scrollbar
  {
    width: 12px;
    background-color: #F5F5F5;
  }

  .notification-dropdown-container::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #929292;
  }


  ul {
    list-style: none;
  }

  .notification-description {
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: center;
  }

  .notification-item {
    position: relative;
    padding: 10px 35px;
    cursor: pointer;
    /*border-bottom: 1px solid #535353;*/
  }

  .notification-title {
    width: 85%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
  }

  .notification-title .created-at {
    font-size: 13px;
    color: #5c5c5c;
    word-spacing: 6px;
  }

  .notification-item:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: 200ms ease-out all;
    background: #0E1112;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    right: 20px;
  }

  .notification-item:hover:before {
    width: 1px;
    top: 35%;
    height: 85%;
    border-radius: 0;
  }

  @media screen and (max-width: 768px) {
    .notification-dropdown-container {
      min-width: unset;
      max-height: unset;
      width: 100%;
    }
    .notification-title {
      font-size: 18px;
    }
  }

  .inline {
    display: inline;
  }
  .notfiTitle{
  font-size: 16px;
}
li.notification-item:nth-child(odd) {
    background: #00000017;
    border-radius: 10px !important;
}
</style>
<style>
.notification-counter {
  padding: 2.25px 6.5px!important;
}

</style>
