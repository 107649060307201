<template>
  <div id="homePage">
    <!--    <Header />-->
    <HomeHeader />
    <Slider />
    <BrandS v-if="$store.getters['front/getHomeData'] != null" />
    <!--   <HomeCategories />-->
    <DiscountedProductSlider />
    <Banners :banners="banners"></Banners>
    <RecommendedProductSlider />
    <template v-if="homeData && homeData.specialProducts">
      <CategoryProductsSlider
        v-for="(item, index, i) in homeData.specialProducts"
        :item="item"
        :key="item.catId"
        :categoryTitle="index"
        :counter="i"
      />
    </template>
    <SuggestedProductSlider />
    <MostsalesProductSlider />
    <main class="main">
      <div>
        <ResponsiveBannerDouble
          class="w-100"
          :item="access($store.getters, 'front/getHomeData.advertise.center_1')"
          :item2="
            access($store.getters, 'front/getHomeData.advertise.center_2')
          "
        />
        <ResponsiveBanner
          class="w-100"
          :item="access($store.getters, 'front/getHomeData.advertise.center')"
          :mobile-item="
            access($store.getters, 'front/getHomeData.advertise.center_mobile')
          "
        />
      </div>
      <Flashes
        v-if="
          $store.getters['front/getHomeData'] != null &&
            $store.getters['front/getHomeData'].flashes != ''
        "
        :flashes="$store.getters['front/getHomeData'].flashes"
      />
      <Weblogs />
      <div>
        <ResponsiveBanner
          class="w-100"
          :item="access($store.getters, 'front/getHomeData.advertise.bottom')"
          :mobile-item="
            access($store.getters, 'front/getHomeData.advertise.bottom_mobile')
          "
        />
      </div>
    </main>
    <section class="position-relative">
      <div
        class="home_mainContent"
        v-if="homeData"
        v-html="homeData.settings.site.home_main_content"
      ></div>
    </section>
  </div>
</template>

<script>
import Header from "@/parts/Front/components/shared/Header";
import Banners from "@/parts/Front/components/Banners";
import Flashes from "@/parts/Front/components/FlashProducts";
import Weblogs from "@/parts/Front/components/Weblogs";
import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import debounce from "lodash/debounce";
import ProductCategories from "@/parts/Front/components/ProductCategories";
import AboutHome from "@/parts/Front/components/home/AboutHome";
import RecommendedProductSlider from "@/parts/Front/components/RecommendedProductSlider";
import SingleBanner from "@/parts/Front/components/SingleBanner";
import ResponsiveBanner from "@/parts/Front/components/ResponsiveBanner";
import Services from "@/parts/Front/components/Services";
import HomeCategories from "@/parts/Front/nicecomponents/HomeCategories";
import RecommendedProduct from "@/parts/Front/components/RecommendedProduct";
import ResponsiveBannerDouble from "@/parts/Front/components/ResponsiveBannerDouble";
import HomeHeader from "@/parts/Front/nicecomponents/HomeHeader";
import Slider from "@/parts/Front/nicecomponents/Slider";
import BrandS from "@/parts/Front/components/BrandS";
import DiscountedProductSlider from "../components/DiscountedProductSlider.vue";
import MostsalesProductSlider from "../components/MostsalesProductSlider.vue";
import SuggestedProductSlider from "../components/SuggestedProductSlider.vue";
import CategoryProductsSlider from "@/parts/Front/components/CategoryProductsSlider.vue";

export default {
  name: "Home",
  components: {
    HomeHeader,
    BrandS,
    ResponsiveBannerDouble,
    RecommendedProduct,
    HomeCategories,
    Services,
    ResponsiveBanner,
    SingleBanner,
    RecommendedProductSlider,
    AboutHome,
    ProductCategories,
    Header,
    Banners,
    Flashes,
    Weblogs,
    LastInstaPosts,
    ScrollToTop,
    Slider,
    DiscountedProductSlider,
    MostsalesProductSlider,
    SuggestedProductSlider,
    CategoryProductsSlider,
  },
  data() {
    return {
      showHomeDesc: false,
      showInstagram: true,
      reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
  },
  mounted() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
    window.addEventListener("resize", this.reRenderInstagramFunction);
  },
  methods: {
    reRenderInstagram() {
      console.log("oki");
      this.showInstagram = false;
      this.$nextTick(() => {
        this.showInstagram = true;
      });
    },
  },
  computed: {
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let adver = this.$store.getters["front/getHomeData"].advertise;
        let banners = [];
        for (const pos in adver) {
          banners.push(adver[pos]);
        }
        return banners;
      } else {
        return [[]];
      }
    },
  },
  metaInfo() {
    return {
      title: this.homeData
        ? "فروشگاه اينترنتي پاکار"
        : "فروشگاه اينترنتي پاکار",
      meta: [
        {
          name: "description",
          content:
            " خرید کتونی و خرید کفش در جدیدترین طرح های زیبا و کتونی هایی با تنوع جورابی،جردن،بزرگپا،زیره ونس،کتونی و.... با قیمت های باورنکردنی و در همه ی سایز ها در فروشگاه اینترنتی پاکار. ",
        },
        {
          name: "title",
          content: "فروشگاه اينترنتي پاکار",
        },
      ],
    };
  },
};
</script>
<style scoped>
div.home_mainContent {
  margin: 24px auto;
  width: 95%;
  position: relative;
}
button#seeMoreContent {
  margin-right: 5%;
  background: none;
  color: #653094;
  font-weight: bolder;
  font-size: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid;
}
</style>
