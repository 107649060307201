<template>
  <DynamicLinker class="banner__container__resp" v-if="mediaQueries.mobileSize && mobileItem && mobileItem.picture" :item="mobileItem">
    <img :src="mobileItem.picture" class="banner" alt="banner">
    <img :src="mobileItem.picture" class="banner" alt="banner">
  </DynamicLinker>
  <DynamicLinker class="banner__container" v-else-if="item && item.picture && item2 && item2.picture" :item="item">
    <img :src="item.picture" class="banner" alt="banner">
    <img :src="item2.picture" class="banner" alt="banner">
  </DynamicLinker>

</template>

<script>
import DynamicLinker from "@/parts/Front/components/shared/DynamicLinker";
export default {
  name: "ResponsiveBannerDouble",
  components: {DynamicLinker},
  inject: ['mediaQueries'],
  props: {
    item: Object,
    item2: Object,
    mobileItem: Object,
    mobileItem2: Object,
  }
}
</script>

<style scoped>
.banner__container, .banner__container__resp {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.banner {
  width: 45%;
}
@media screen and (max-width: 768px) {
  .banner__container, .banner__container__resp {
    gap: 1rem;
    flex-direction: column;
    align-items: center;
  }

  .banner {
    width: 95%;
  }
}


</style>
