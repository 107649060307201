<template>
  <button style="background: none"
          @click.prevent="showLogin"
          class="logged-in-btn">
    <UserSvg/>
    <transition name="fade">
      <div v-if="showOptions" id="loginUserOptions" :style="{left: atlasTheme ? '50px' : ''}">
        <router-link
            to="/user-panel"
            @click="gotoUserPanel()"
            :class="{ disabledLogout: disabledlLogout }"
        >مشاهده حساب کاربری
        </router-link
        >
        <button
            class="logout-btn"
            @click="signout()"
            :class="{ disabledLogout: disabledlLogout }"
        >
          خروج <i class="fa fa-signout"></i>
        </button>
      </div>
    </transition>
  </button>
</template>
<script>
import UserSvg from "@/parts/Front/nicecomponents/svgs/UserSvg";

export default {
  components: {UserSvg},
  props: {
    atlasTheme: {Boolean}
  },
  data() {
    return {
      showOptions: false, disabledlLogout: false
    }
  },
  methods: {
    gotoUserPanel() {
      this.showOptions = false;
    },
    signout() {
      window
          .swal({
            title: "آیا میخواهید خارج شوید؟",

            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله خارج شو"],
          })
          .then((willDelete) => {
            if (willDelete) {
              this.disabledlLogout = true;
              this.$axios.post("/customer/logout").then((res) => {
                console.log('okokokko')
                this.disabledlLogout = false;
                this.showOptions = false;
                // پاک کردن اطلاعات مربوط به کاربر از کوکی و ویو ایکس
                this.$root.deleteCookie("Authorization");
                delete this.$axios.defaults.headers.common["Authorization"]
                this.$store.commit("front/setLoginStatus", false);
              });
            }
          });
    },
    showLogin() {
      if (this.isLogin) {
        // this.showOptions = !this.showOptions
        this.$router.push('/user-panel')
      } else {
        window.$('#loginModal').modal('show')

      }
    }
  },
  computed: {

    // دریافت اطلاعات کاربر
    FullName() {
      return this.$store.getters["front/getFullName"];
    },
    isLogin() {
      return this.$store.getters["front/getLoginStatus"];
    },
  }
};
</script>
<style scoped>
#loginUserOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  top: 100px;
  box-shadow: 0 3px 3px -5px;
  z-index: 20;
  left: 0;
}

#loginUserOptions button,
#loginUserOptions a {
  padding: 10px;
  background-color: var(--color-theme4);
  color: #ffffff !important;
  transition: all 1s;
}

#loginUserOptions button:hover,
#loginUserOptions a:hover {
  background-color: var(--color-theme);
  color: white !important;
}

@media (max-width: 768px) {
  #loginUserOptions {
    display: none;
  }
}

.disabledLogout {
  pointer-events: none;
  opacity: 0.5;
}

#showOptionBtn {
  position: relative;
}



a {
  font-size: 14px;
}

.bi-person {
  transform: translateY(-2px);
}

.logout-btn {
  border-radius: 0 0 10px 10px;
}

.logged-in-btn {
}

</style>
