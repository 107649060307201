<template>
  <div class="menu">
    <ul>
      <li><a href="">زنانه <DropDown/> </a></li>
      <li><a href=""> مردانه <DropDown/> </a></li>
      <li><a href=""> اکسسوری </a></li>
      <li><a href=""> کیف </a></li>
      <li><a href=""> تماس باما </a></li>
      <li><a href=""> درباره ما </a></li>
    </ul>
  </div>
</template>

<script>
import DropDown from "@/parts/Front/nicecomponents/svgs/DropDown";

export default {
  name: "MenuDesktop",
  components: {
    DropDown
  }
}
</script>

<style scoped>

.menu {
  flex-grow: 1;
  margin-right: 60px;
}

.menu >>> svg {
  vertical-align: baseline;
}

.menu ul {
  display: flex;
  align-items: center;
  margin-bottom: 0!important;
}

.menu ul li {
  margin-left: 3rem;
  list-style-type: none;
  font-weight: bold;
}

.menu a {
  text-decoration: none;
  color: #333;
  font-size: 17px;
  white-space: nowrap;
}
</style>
