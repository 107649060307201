<template>
  <section
    v-if="item && item.products && item.products.length"
    class="container mb-5"
    :id="`categorySliderSection${item.catId}`"
  >
    <div>
      <div
        class="d-flex flex-nowrap py-3 py-lg-4 pl-3 position-relative background-container"
        :style="`background-color: ${item.color ? item.color : '#3c1a5b'};`"
      >
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <template v-if="!isLtr">
              <article class="static-slide swiper-slide">
                <router-link
                  class="w-100 h-100 d-inline-block cursor-pointer"
                  :to="`/products/category/${item.catId}`"
                >
                  <div
                    class="w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center"
                  >
                    <h4 class="text-white fontsize-xheavy mb-3">
                      {{ categoryTitle }}
                    </h4>
                    <img
                      v-if="item.image && item.image.url"
                      class="w-100 mb-3"
                      :src="item.image.url"
                      :alt="categoryTitle"
                    />
                    <img
                      class="w-100 mb-3"
                      v-else
                      src="@/assets/images/amazingBox.png"
                      alt=""
                    />
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-white fontsize-small m-0">مشاهده همه</p>
                      <i class="text-white mr-2 pb-1 fa fa-angle-left"></i>
                    </div>
                  </div>
                </router-link>
              </article>
            </template>
            <Product
              class="swiper-slide"
              v-for="(product, index) in item.products"
              :key="index"
              :product="product"
              :priceColorFlag="true"
            />
            <template v-if="isLtr">
              <article class="static-slide swiper-slide">
                <router-link
                  class="w-100 h-100 d-inline-block cursor-pointer"
                  :to="`/products/category/${item.catId}`"
                >
                  <div
                    class="w-100 h-100 d-flex align-items-center justify-content-center flex-column text-center"
                  >
                    <h4 class="text-white fontsize-xheavy mb-3">
                      {{ categoryTitle }}
                    </h4>
                    <img
                      v-if="item.image && item.image.url"
                      class="w-100 mb-3"
                      :src="item.image.url"
                      :alt="categoryTitle"
                    />
                    <img
                      class="w-100 mb-3"
                      v-else
                      src="@/assets/images/amazingBox.png"
                      alt=""
                    />
                    <div
                      class="d-flex align-items-center justify-content-center"
                    >
                      <p class="text-white fontsize-small m-0">مشاهده همه</p>
                      <i class="text-white mr-2 pb-1 fa fa-angle-left"></i>
                    </div>
                  </div>
                </router-link>
              </article>
            </template>
          </div>
        </div>
        <div class="swiper-button-next">
          <ZArrowBold style="transform: rotate(180deg)" />
        </div>
        <div class="swiper-button-prev">
          <ZArrowBold />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
let breakpoints = {
  300: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  500: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  992: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};
import Product from "@/parts/Front/components/product/Product";
import Swiper from "swiper/swiper-bundle.min";
import ZArrowBold from "@/parts/Front/components/shared/ZArrowBold";

export default {
  components: {
    Product,
    ZArrowBold,
  },
  props: {
    item: Object,
    categoryTitle: String,
    counter: Number,
  },
  data() {
    return {
      lastSildeIndex: 0,
    };
  },
  mounted() {
    this.initialSlider(false);
  },
  watch: {
    "item.products": {
      handler: function() {
        this.initialSlider(true);
      },
      deep: true,
    },
  },
  methods: {
    initialSlider(kill = false) {
      this.lastSildeIndex =
        document.querySelectorAll(
          `#categorySliderSection${this.item.catId} .swiper-slide`
        ).length - 1;
      this.initSwiper("categoryProductSlider", kill, () => {
        let swiper = new Swiper(
          `#categorySliderSection${this.item.catId} .swiper-container`,
          {
            loop: false,
            speed: 800,
            autoplay: false,
            breakpoints,
            initialSlide: this.isLtr ? this.lastSildeIndex : 0,
            navigation: {
              nextEl: `#categorySliderSection${this.item.catId} .swiper-button-next`,
              prevEl: `#categorySliderSection${this.item.catId} .swiper-button-prev`,
            },
          }
        );
      });
    },
  },
  computed: {
    isLtr() {
      return this.counter % 2 == 0 ? true : false;
    },
  },
};
</script>
<style scoped>
.background-container {
  border-radius: 25px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}
.swiper-button-prev,
.swiper-button-next {
  background: var(--color-theme);
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.swiper-button-prev {
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}
.swiper-button-next {
  left: -10px;
  right: unset;
  top: 50%;
  transform: translateY(-50%);
}
</style>
