<template>
  <section  v-if="hasAnyBanners" class="container my-5 paaakarCategories">
    <div id="categoriesBanner" class="d-flex flex-wrap justify-content-center align-items-baseline">
      <BigTitle class="mb-2 mb-sm-4" title="دسته بندی های پاکار" />

      <!--      <div class="line w-50"></div>-->
    </div>
    <div class="banners d-flex justify-content-between banner ">
    <router-link
      v-for="(banner, index) in banners"
      :key="index"
      :to="
        banner
          ? banner.linkable_type != null
            ? findUrl(banner.linkable_type, banner.linkable_id)
            : banner.link
          : ''
      "
      :target="banner && banner.new_tab == 1 ? '_blank' : ''"
      class="overflow-hidden"
    >
      <img loading="lazy" v-if="banner" :src="banner.picture" class="w-100" alt="" />
    </router-link>
  </div>
  </section>
</template>

<script>
import findUrl from "@/libraries/links/LinkResolver";
import BigTitle from "../nicecomponents/shared/BigTitle.vue";
export default {
    name: "Banners",
    props: {
        banners: Array,
    },
    methods: {
        findUrl,
    },
    computed: {
        hasAnyBanners() {
            let ok = false;
            this.banners.every(banner => {
                if (banner) {
                    ok = true;
                    return false;
                }
                return true;
            });
            return ok;
        }
    },
    components: { BigTitle }
};
</script>

<style>

#categoriesBanner .big-title{
  background: url(../../../assets/images/categories.png);
  background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
 </style>
