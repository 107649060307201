<template>
  <div class="accordion mt-3" id="accordionProductFilter">
    <template v-if="brands != null">
      <div class="card mb-1">
        <div class="card-header d-flex justify-content-between p-0">
          <router-link
            :to="{ name: 'products' }"
            class="fontsize-small text-color-666 my-auto py-1"
          >
            همه برندها
          </router-link>
        </div>
      </div>
      <div class="card mb-1" v-for="(brand, index) in brands" :key="index">
        <div class="card-header d-flex justify-content-between p-0">
          <router-link
            :to="{ name: 'product.brands', params: { brand: brand.id } }"
            class="fontsize-small text-color-666 my-auto py-1"
            :class="brand.id == $route.params.brand ? 'category-active' : ''"
          >
            {{ brand.name }}
          </router-link>
        </div>
      </div>
    </template>
    <template v-else>
      <b-skeleton class="mx-4"></b-skeleton>
      <b-skeleton class="mx-4"></b-skeleton>
      <b-skeleton class="mx-4"></b-skeleton>
    </template>
  </div>
</template>
<script>
import { BSkeleton } from "bootstrap-vue";

export default {
  components: {
    BSkeleton,
  },
  props: {
    brands: Array,
  },
};
</script>
<style>
.products-list-filter .card-header > .category-active::before,
.products-list-filter .card-body .category-active::before {
  background-color: #713348;
}

.products-list-filter .card-header > .category-active,
.products-list-filter .card-body .category-active {
  color: #c92f2f;
}
</style>
