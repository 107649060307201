<template>
  <div class="beeeb top__header">
        <FirstHeader />

    <Header/>

  </div>
</template>


<script>
import Header from "@/parts/Front/nicecomponents/subcomponents/Header";
import FirstHeader from "@/parts/Front/nicecomponents/subcomponents/FirstHeader";

export default {
  name: 'HomeHeader',
  components: {
    Header,FirstHeader
  }
};
</script>


<style scoped>
.beeeb{
  background-color: brown;
}
.top__header {
  /* position: absolute; */
  width: 100%;
  margin:0 auto ;
  /* left: 0; */
  /* right: 0; */
  background-color: #fff;
  border-radius: 10px;
  z-index: 8;
}

@media screen and (max-width: 1200px) {
  .top__header {
    width: 90%;
  }

  .top__header >>> .menu {
    margin-right: -24px;
  }
}

@media screen and (max-width: 992px) {
  .top__header {
    margin: 1.2rem auto;
  }
}
</style>
