<template>
  <div :class="{mobile: mobileMode}" class="header">
    <RespMenu ref="respMenu" />
    <nav id="mainWhiteHeader" class="header__nav">
      <div class="icons">
        <div>
          <CartButton class="icon-item" v-if="!mobileMode" />
          <LoginUser class="icon-item" v-if="!mobileMode" />
          <SearchSvg class="icon-item mr-2" title="جستجو..." @click="openSearch" />
        </div>
      </div>
      <div  v-if="homeData&&mobileMode" class="logo">
        <router-link to="/"><h1><img :src="'https://api.paaakar.com/storage/'+homeData.settings.site.logo" alt="لوگو پاکار"></h1></router-link>
      </div>
      <Nav v-if="!mobileMode"/>
      <NavSvg class="icon-item" @click="showRespMenu" id="all-categories" width="22" height="22"  v-if="mobileMode" />
    
      <div  v-if="homeData&&!mobileMode" class="logo">
        <router-link to="/"><h1><img :src="'https://api.paaakar.com/storage/'+homeData.settings.site.logo" alt="لوگو پاکار"></h1></router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import ShopSvg from "@/parts/Front/nicecomponents/svgs/ShopSvg";
import UserSvg from "@/parts/Front/nicecomponents/svgs/UserSvg";
import SearchSvg from "@/parts/Front/nicecomponents/svgs/SearchSvg";
import MenuDesktop from "@/parts/Front/nicecomponents/subcomponents/MenuDesktop";
import NavSvg from "@/parts/Front/nicecomponents/svgs/NavSvg";
import {openSearch} from "../../../../libraries/functions";
import LoginUser from "@/parts/Front/components/LoginUser";
import CartButton from "@/parts/Front/components/cart/CartButton";
import Nav from "@/parts/Front/components/Nav";
import RespMenu from "@/parts/Front/components/RespMenu";

export default {
  name: "Header",
  components: {RespMenu, Nav, CartButton, LoginUser, NavSvg, MenuDesktop, SearchSvg, UserSvg, ShopSvg},
  inject: ['mediaQueries'],
  data() {
    return {
      logo : require("../../../../assets/images/logo.png"),
    }
  },
  mounted(){
    window.addEventListener("scroll",this.setHeaderStyle)
  },
  beforeDestroy(){
    window.removeEventListener("scroll",this.setHeaderStyle)
  },
  computed: {
    mobileMode() {
      return this.mediaQueries.slarge
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  methods: {
    changeHeaderStyle(changeFlag){
      let header=document.getElementById("mainWhiteHeader");

      if(changeFlag){
        header.style.position="fixed";
        header.style.left="0";
        header.style.width="100%";
        header.style.background="white"
        header.style.zIndex="1000";
        header.style.boxShadow="0 0 10px"
        header.classList.add("showFromTop")
        if(document.getElementById('footer-buttons')){
          document.getElementById('footer-buttons')
          .classList.add('footer-buttons1')
        }
      }else{
        header.removeAttribute("style")
        header.classList.remove("showFromTop")
        if(document.getElementById('footer-buttons')){
          document.getElementById('footer-buttons')
          .classList.remove('footer-buttons1')
        }
      }
    },
    setHeaderStyle(){
      if(this.getYPosition()>120){
        this.changeHeaderStyle(true)
      }else{
        this.changeHeaderStyle(false)
      }
    },
     getYPosition(){
  var top  = window.pageYOffset || document.documentElement.scrollTop
  return top;
},
    openSearch,
    showRespMenu() {
      this.$refs.respMenu.allCategoriesToggle()
    },
  }
}
</script>

<style scoped>


.header {

  box-shadow: 1px 1px 5px gray;

}

.header__nav {
  transition: all 1.5s;
  width: 100%;
  display: grid;
  grid-template-columns: 200px auto 200px;
  text-align: center;
  align-items: center;
  padding: 4px 0 0;
  direction: ltr;
  
}


.mobile .header__nav {
  display: flex;
  justify-content: space-between;
}

.icons > div {
  display: flex;
  margin-right: 0.1rem;
  background-color: #fff;
}



.logo h1 img {
  height: 50px;
  width: 120px;
}

.logo h1 {
  margin: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile .logo h1 {
  justify-content: end;
  margin-left: 20px;
}

 .icon-item {
  width: 22px;
  height: 22px;
  background-color: #fff;
  margin: 0 20px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .icons >>> .icon-item {
    margin: 0 12px;
  }
  .menu ul li {
    margin-left: 2.1rem;
  }
}

@media screen and (max-width: 992px) {

  .icons >>> .icon-item:first-child {
    margin-right: 10px;
  }

  .icons >>> .icon-item {
    margin: 0 7px;
  }
}
</style>
<style>
.showFromTop #loginUserOptions{
top: 100%;
}
</style>